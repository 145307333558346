.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mobile-hide {
  display: block;
}
.searchbar{
  width: 300px !important;
}

@media only screen and (max-width: 375px) {
  .searchbar {
    width: 250px !important;
  }
}
@media only screen and (max-width: 320px) {
  .searchbar {
    width: 220px !important;
  }
}
@media only screen and (max-width: 425px) {
  .mobile-hide {
    display: none !important;
  }
}

.grayscale {
  filter: grayscale(1);
  background-color: gray;
}
.Toastify__toast {
  text-align: center!important;
  background-color: #C92332!important;
}
.Toastify__toast-body {
  text-align: center!important;
  background-color: #C92332!important;
  color: white!important;
}

.badge {
  padding-left: 4px;
  padding-right: 4px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  vertical-align: top;
  margin-left: -9px;
}

.label-warning[href],
.badge-warning[href] {
  background-color: #c67605;
}

/* @media (max-width: 768px) {
  
} */
.shipping-container{
  padding-top: 5px;
  padding-bottom: 5px;
}

.shipping-item:hover{
  background-color: rgb(241, 241, 241);
}

.shipping-item.selected{
  background-color: rgb(241, 241, 241);
}

.float-wa{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:37px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}

.my-float-wa{
	margin-top:20px;
}

.image-gallery-wrapper {
  margin-top: 5rem;
  max-width: 800px;
  width: 80%;
  margin: 0 auto;
  text-align: center;
  /* border: 1px solid rgb(146, 129, 242); */
  /* box-shadow: #2b15453d 1px 10px 10px 5px; */
}

@media only screen and (max-width: 425px) {
    .image-gallery-wrapper {
        max-width: 800px;
        width: 80%;
    }
}
.slider {
  position: relative;
}
.slider::before {
  content: '';
  position: absolute;
  top: 0;
  left: 100%;
  width: 10000%;
  height: 100%;
}
.swal2-cancel{
  margin-left: 10px;
}

.swal2-confirm{
  margin-left: 10px;
}